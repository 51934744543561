<template>
	<!-- Start Template Root Div -->
	<div>
		<WaveLoader v-if="searchSurfWaiversLoading" />

		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div>
							<h1 class="text-2xl">
								Surf Ops - Customer Check-In
							</h1>
						</div>
						<div>
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<!--content-->
			<div
				class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-10"
			>
				<!--body-->
				<div class="relative p-6 flex-auto">
					<p class="mb-6 font-bold">
						<!--For your safety please view the following two videos: -->
						For your safety please watch the relevant video for the
						session you have booked:
					</p>

					<!-- Video -->
					<div class="justify-center items-center">
						<div
							v-if="surfersBay.length > 0"
							class="w-2/3 mx-auto my-6"
						>
							<div class="mb-2 font-semibold">
								<p
									class="inline capitalize"
									v-bind:key="'surfer' + surferIndex"
									v-for="surferIndex in surfersBay"
								>
									{{
										surfers[surferIndex].firstName +
											" " +
											surfers[surferIndex].lastName
									}}
									<span v-if="surfersBay[surferIndex + 1]"
										>,
									</span>
								</p>
							</div>
							<p class="mb-2 font-semibold">
								Please watch this video as you have booked a
								Bay-Based Session.
							</p>
							<div
								class="relative w-full rounded-md shadow overflow-hidden"
							>
								<!-- - Video One - -->
								<video
									v-on:click="playVideo($event.currentTarget)"
									class="cursor-pointer"
									v-on:ended="surfersBayWatched = true"
									width="100%"
								>
									<source
										src="/THE_BAY.mp4"
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
								<div
									class="player-hidden absolute flex inset-0 w-full h-full bg-white bg-opacity-25 pointer-events-none"
								>
									<img
										class="h-64 m-auto"
										src="/play-img.png"
									/>
								</div>
							</div>
						</div>

						<div
							v-if="surfersReef.length > 0"
							class="w-2/3 mx-auto my-6"
						>
							<div class="mb-2 font-semibold">
								<p
									class="inline capitalize"
									v-bind:key="'surfer' + surferIndex"
									v-for="surferIndex in surfersReef"
								>
									{{
										surfers[surferIndex].firstName +
											" " +
											surfers[surferIndex].lastName
									}}
									<span v-if="surfersReef[surferIndex + 1]"
										>,
									</span>
								</p>
							</div>
							<p class="mb-2 font-semibold">
								Please watch this video as you have booked a
								Reef-Based Session.
							</p>
							<div
								class="relative w-full rounded-md shadow overflow-hidden"
							>
								<!-- - Video Two - -->
								<video
									v-on:click="playVideo($event.currentTarget)"
									class="cursor-pointer"
									v-on:ended="surfersReefWatched = true"
									width="100%"
								>
									<source
										src="/THE_REEF.mp4"
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
								<div
									class="player-hidden absolute flex inset-0 w-full h-full bg-white bg-opacity-25 pointer-events-none"
								>
									<img
										class="h-64 m-auto"
										src="/play-img.png"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Footer -->
				<div
					class="w-full flex flex-col mb-12 justify-center items-center"
				>
					<button
						@click="acceptVideos"
						:disabled="!buttonActivated()"
						class="w-9/12 md:w-6/12 lg:w-3/12 bg-pear-500 disabled:opacity-0 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
					>
						Continue
					</button>
					<p v-if="!buttonActivated()" class="text-lg text-gray-700">
						Please watch the whole video before continuing
					</p>
				</div>
			</div>
		</main>
	</div>
</template>
<script>
	import { mapState, mapActions } from "vuex";

	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	export default {
		data() {
			return {
				surfers: [],

				surfersBay: [],
				surfersBayWatched: false,

				surfersReef: [],
				surfersReefWatched: false
			};
		},
		components: {
			WaveLogo,
			WaveLoader
		},
		created() {
			if (this.searchSurfWaiversActiveUser === null) {
				this.$router.push({ name: "SurfOps/CheckIn" });
			}
		},
		computed: {
			...mapState("surfers", [
				"searchSurfWaiversLoading",
				"searchSurfWaiversResults",
				"searchSurfWaiversSuccess",
				"searchSurfWaivers",
				"searchSurfWaiversActiveUser"
			]),
			activeDate() {
				return this.$moment()
					.utc()
					.startOf("day")
					.toISOString();
			}
		},
		async mounted() {
			await this.getSurferRequirements();
			this.getSurferVideos();
			this.acceptVideos();
		},
		methods: {
			...mapActions([
				"surfers/searchSurfersWaivers",
				"surfers/staticUpdateSurfersWaiver"
			]),
			buttonActivated() {
				if (
					(this.surfersBay.length > 0 && !this.surfersBayWatched) ||
					(this.surfersReef.length > 0 && !this.surfersReefWatched)
				)
					return false;
				return true;
			},
			playVideo(element) {
				if (!element.classList.contains("playing")) {
					element.classList.add("playing");
					element.play();
				} else {
					element.classList.remove("playing");
					element.pause();
				}
			},
			updateRequirements(requirements, waiver) {
				// Event Amend Requirements
				if (!waiver.event) {
					requirements.videoRequiredBay = true;
					requirements.videoRequiredReef = true;
					requirements.waiverRequired = true;
				} else {
					if (waiver.event.waiverRequired)
						requirements.waiverRequired = true;

					switch (waiver.event.videoRequired) {
						case "reef":
							requirements.videoRequiredReef = true;
							break;

						case "bay":
							requirements.videoRequiredBay = true;
							break;
					}
				}

				return requirements;
			},
			async getSurferRequirements() {
				// Wait for the looping of the Surfers to finish
				await this.$asyncForEach(
					this.searchSurfWaivers,
					async surfer => {
						// Setup Requirements
						surfer.requirements = {
							videoRequiredBay: false,
							videoRequiredReef: false,
							waiverRequired: false
						};

						// Get the Fresh API Objects
						surfer.serverObjects = await this
							.searchSurfWaiversResults[surfer.firstName];

						// Loop over and Set Requirements
						surfer.serverObjects.forEach(obj => {
							surfer.requirements = this.updateRequirements(
								surfer.requirements,
								obj
							);
						});

						// Store Surfer
						this.surfers.push(surfer);
					}
				);
			},
			getSurferVideos() {
				this.surfers.map((surfer, index) => {
					if (surfer.requirements.videoRequiredBay)
						this.surfersBay.push(index);

					if (surfer.requirements.videoRequiredReef)
						this.surfersReef.push(index);
				});
			},
			async acceptVideos() {
				if (!this.buttonActivated()) return;

				// Wait for the looping of the Surfers to finish
				await this.$asyncForEach(
					this.searchSurfWaivers,
					async surfer => {
						if (surfer.onSite === true) {
							surfer.hasSeenVideo = true;

							await this.$asyncForEach(
								surfer.serverObjects,
								async obj => {
									// Update the Account
									if (surfer.requirements.videoRequiredReef)
										await this.$accountsAPI.updateAccountPartial(
											{
												uuid: obj.account,
												video_reef_last_watched: new Date().toISOString()
											}
										);
									else if (
										surfer.requirements.videoRequiredBay
									)
										await this.$accountsAPI.updateAccountPartial(
											{
												uuid: obj.account,
												video_bay_last_watched: new Date().toISOString()
											}
										);

									// Update the Waiver
									obj.hasSeenVideo = true;
									obj.activeDate = this.activeDate;
									await this[
										"surfers/staticUpdateSurfersWaiver"
									](obj);
								}
							);
						}
					}
				);

				// Check Terms Parameter
				if (this.$route.query.showDisclaimer == true)
					this.$router.push({
						name: "SurfOps/CheckInTerms",
						query: {
							showVideo: false,
							showDisclaimer: this.$route.query.showDisclaimer
						}
					});
				// Complete
				else this.$router.push({ name: "SurfOps/CheckInComplete" });
			}
		}
	};
</script>
<style lang="scss" scoped>
	ul {
		padding: inherit;
	}
</style>
